import superagent from 'superagent'
import { isEqual, isEmpty } from 'lodash'
import L from 'leaflet'

import { MapType, BusStationType } from './types'
import busStop from './assets/bus-stop-icon.png'

// const API_URL = 'http://apitestadmin.coddvrn.ru'
// const API_URL = 'https://apiadmin.eogpp-vrn.ru/'
// const API_URL = 'https://apiadmin.coddvrn.ru/external'
const API_URL = 'https://apiadmin.eogpp-vrn.ru/external'

const Api = {
  newRoutesWithStations: `${API_URL}/newRoutes/withStations`,
  routesWithNewStations: `${API_URL}/routes/withStations?forNewRoutes=true`,
  newBusStations: `${API_URL}/newBusStations`,
  routesEdges: 'https://vrnbus.herokuapp.com/bus_route_edges',
}

export const fetchRoutes = () => superagent.get(Api.newRoutesWithStations).accept('application/json')
export const fetchOldRoutes = () => superagent.get(Api.routesWithNewStations).accept('application/json')
export const fetchStations = () => superagent.get(Api.newBusStations).accept('application/json')
export const fetchEdges = () => superagent.get(Api.routesEdges)

export const fetchPath = (from, to) =>
  superagent.get(`${API_URL}/newRoutes/path?startStationId=${from.value}&endStationId=${to.value}`)

const myIcon = L.icon({
  iconUrl: busStop,
  iconSize: [15, 15],
})

export const addStations = (stations: BusStationType[], mapRef: MapType) => {
  stations.forEach((station) => {
    const marker = L.marker([station.latitude, station.longitude], { icon: myIcon, title: station.name })
    marker.bindTooltip(station.name)
    marker.addTo(mapRef.current)
  })
}

const addEdgesRoute = (mapRef: MapType, busStations, edges, routeIndex) => {
  let points = []
  busStations.forEach((bs, bsIndex) => {
    const isLast = bsIndex !== busStations.length - 1
    if (isLast) {
      const nextBs = busStations[bsIndex + 1]
      const currentEdge = edges.find((edge) => isEqual(edge.edge_key, [bs.id, nextBs.id]))
      if (currentEdge) {
        const cookedPoints = currentEdge.points.map((p) => [p.lat, p.lng])
        points = [...points, ...cookedPoints]
      } else {
        points = [...points, [bs.latitude, bs.longitude]]
      }
    } else {
      points = [...points, [bs.latitude, bs.longitude]]
    }
  })

  const strokeColor = routeIndex === 0 ? '#116CA9' : '#F6653C'

  const polyline = L.polyline(points, { color: strokeColor }).addTo(mapRef.current)
  const bounds = polyline.getBounds()
  if (!isEmpty(bounds)) {
    mapRef.current.fitBounds(bounds)
  }
}

export const clearMap = (mapRef: { current: L.Map }) => {
  if (mapRef.current) {
    mapRef.current.eachLayer((layer) => {
      if (!layer.getAttribution()) {
        mapRef.current.removeLayer(layer)
      }
    })
  }
}

export const addRoute = (routes: BusStationType[][], mapRef: MapType, edges?) => {
  const routesLength = routes.length
  routes.forEach((busStations, index) => {
    let routeIndex = index % 2
    if (routesLength > 2) {
      routeIndex = index > 1 ? 1 : 0
    }

    addEdgesRoute(mapRef, busStations, edges, routeIndex)
    addStations(busStations, mapRef)
  })
}

export const addRoutes = (stations, route, mapRef, edges) => {
  clearMap(mapRef)
  const forwardDirectionStations = [route.forwardDirectionStations]
  const backDirectionStations = [route.backDirectionStations]

  addRoute([...forwardDirectionStations, ...backDirectionStations], mapRef, edges)
}
