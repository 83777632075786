import { noop, isEqual } from 'lodash'
import ym from 'react-yandex-metrika'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'

import Map from '../../Components/Map'
import { DirectDirectionType, RouteType, SelectOptionType } from '../../types'
import { addRoute, clearMap, fetchPath } from '../../actions'
import { RootStateType } from '../../redux/reducers'

import { compareByScore, fuzzysearch, fuzzysearchScore } from './fuzzysearch'
import StationRoutes from './StationRoutes'
import DirectRoutes from './DirectRoutes'
import TransferRoutes from './TransferRoutes'

const StationsScreen = memo(() => {
  const mapRef = useRef(null)
  const edges = useSelector((state: RootStateType) => state.edges)
  const routes = useSelector((state: RootStateType) => state.routes)
  const [directRoutes, setDirectRoutes] = useState<DirectDirectionType[]>([])
  const [currentRoute, setCurrentRoute] = useState<RouteType[]>(null)
  const [oneTransferRoutes, setOneTransferRoutes] = useState<DirectDirectionType[][]>([])

  const [stationRoutes, setStationRoutes] = useState<RouteType[]>([])
  const stations = useSelector((state: RootStateType) => state.stations)
  const options: SelectOptionType[] = stations.map((station) => ({ value: station.id, label: station.name }))
  const history = useHistory()

  const urlQuery = new URLSearchParams(useLocation().search)
  const srcStationId = urlQuery.get('from')
  const dstStationId = urlQuery.get('to')
  const srcOption = options.find((item) => item.value.toString() === srcStationId)
  const dstOption = options.find((item) => item.value.toString() === dstStationId)

  const [from, setFrom] = useState(null)
  const [to, setTo] = useState(null)
  const [srcOptionText, setSrcOptionText] = useState(null)
  const [dstOptionText, setDstOptionText] = useState(null)

  const fromTitle = srcOption?.label ? `от ${srcOption.label}` : ''
  const toTitle = dstOption?.label ? `до ${dstOption.label} ` : ''
  document.title = `Как доехать ${fromTitle} ${toTitle}| Новая маршрутная сеть Воронежа`

  const unifyText = (word): string => word.toLocaleLowerCase().replace('ё', 'е')

  const getOptions = useCallback(
    (optionText) => {
      if (!optionText) {
        return options
      }
      optionText = unifyText(optionText)

      const mappedOptions = options
        .map((option) => ({ score: fuzzysearchScore(optionText, unifyText(option.label)), option }))
        .filter((value) => value.score.found)

      if (mappedOptions.length === 0) {
        return []
      }

      mappedOptions.sort((a, b) => compareByScore(a.score, b.score))
      return mappedOptions.map((value) => value.option)
    },
    [options]
  )

  const handleAddRoute = useCallback(
    (selectedRoutes, addingRoutes) => {
      if (Boolean(currentRoute) && isEqual(currentRoute, addingRoutes)) {
        return
      }
      setCurrentRoute(addingRoutes)
      clearMap(mapRef)
      addRoute(selectedRoutes, mapRef, edges)
    },
    [currentRoute, edges]
  )
  const handleOneStationRoutes = useCallback(
    (option?) => {
      const filteredRoutes = option
        ? routes.filter(
            (r) =>
              r.newRouteStatus.name !== 'Ликвидируется' &&
              [...r.forwardDirectionStations, ...r.backDirectionStations].map((st) => st.id).includes(option.value)
          )
        : []
      setStationRoutes(filteredRoutes)
    },
    [routes]
  )

  const updatePageUrl = useCallback(
    (newFrom, newTo) => {
      const params = new URLSearchParams()
      const setParam = (key, value) => value && params.set(key, value)
      setParam('from', newFrom?.value)
      setParam('to', newTo?.value)

      const newHistoryItem = params.toString()
      if (history.location.search !== newHistoryItem) {
        history.push(`?${newHistoryItem}`)
      }
    },
    [history]
  )

  const handleSetBothDirection = useCallback(
    (newFrom, newTo) => {
      setFrom(newFrom)
      setTo(newTo)
      setCurrentRoute(null)
      setOneTransferRoutes([])
      setStationRoutes([])
      clearMap(mapRef)

      if (newFrom && newTo) {
        fetchPath(newFrom, newTo)
          .then(
            (response: {
              body: { directDirections: DirectDirectionType[]; oneTransferDirections: DirectDirectionType[][] }
            }) => {
              setDirectRoutes(response.body.directDirections)
              setOneTransferRoutes(response.body.oneTransferDirections)
            }
          )
          .catch(noop)
      } else {
        handleOneStationRoutes(newFrom || newTo)
      }
      updatePageUrl(newFrom, newTo)
    },
    [handleOneStationRoutes, updatePageUrl]
  )

  const handleSetDirection = useCallback(
    (option, direction) => {
      const newFrom = direction === 'from' ? option : from
      const newTo = direction === 'from' ? to : option
      handleSetBothDirection(newFrom, newTo)
    },
    [from, to, handleSetBothDirection]
  )

  const handleSetFrom = useCallback((option) => handleSetDirection(option, 'from'), [handleSetDirection])
  const handleSetTo = useCallback((option) => handleSetDirection(option, 'to'), [handleSetDirection])

  const customFilterOption = (option, rawInput) => {
    if (rawInput.length === 0) {
      return true
    }
    const words = unifyText(rawInput)
    const optionText = unifyText(option.label)
    return fuzzysearch(words, optionText)
  }

  const objectsEquals = (a, b) => JSON.stringify(a) === JSON.stringify(b)

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ym('hit', '/stations')
    }
    const needUpdate = (srcOption || dstOption) && (!objectsEquals(srcOption, from) || !objectsEquals(dstOption, to))
    if (routes.length > 0 && needUpdate) {
      handleSetBothDirection(srcOption, dstOption)
    }
  }, [routes, srcOption, dstOption, from, to, handleSetBothDirection, stationRoutes, oneTransferRoutes])

  return (
    <div className='App'>
      <div className='b-panel'>
        <>
          <div className='b-selects'>
            <Select
              placeholder='Остановка отправления'
              noOptionsMessage={({ inputValue }): string => `Не можем найти "${inputValue}"`}
              isClearable
              value={from}
              onChange={handleSetFrom}
              onInputChange={setSrcOptionText}
              options={getOptions(srcOptionText)}
              filterOption={customFilterOption}
              className='b-select'
            />
            <Select
              placeholder='Остановка назначения'
              noOptionsMessage={({ inputValue }) => `Не можем найти "${inputValue}"`}
              isClearable
              value={to}
              onChange={handleSetTo}
              onInputChange={setDstOptionText}
              options={getOptions(dstOptionText)}
              filterOption={customFilterOption}
              className='b-select'
            />
          </div>

          <div>
            {([from, to].includes(null) || [from, to].includes(undefined)) && stationRoutes.length > 0 && (
              <StationRoutes
                handleAddRoute={handleAddRoute}
                stationRoutes={stationRoutes}
                currentRoute={currentRoute}
              />
            )}

            {Boolean(from) && Boolean(to) && directRoutes.length > 0 && (
              <DirectRoutes directRoutes={directRoutes} currentRoute={currentRoute} handleAddRoute={handleAddRoute} />
            )}
            {Boolean(from) && Boolean(to) && oneTransferRoutes.length > 0 && (
              <TransferRoutes
                oneTransferRoutes={oneTransferRoutes}
                currentRoute={currentRoute}
                handleAddRoute={handleAddRoute}
              />
            )}
          </div>
        </>
      </div>
      <Map mapRef={mapRef} className='b-map' />
    </div>
  )
})

StationsScreen.displayName = 'StationsScreen'

export default StationsScreen
