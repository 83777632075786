import React, { memo, useCallback, useEffect, useState } from 'react'
import ym from 'react-yandex-metrika'
import { Link } from 'react-router-dom'
import { groupBy } from 'lodash'
import { useSelector } from 'react-redux'

import { RouteType } from '../../types'
import { RootStateType } from '../../redux/reducers'
import { routeStatus } from '../MainScreen'

const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })

const RoutesScreen = memo(() => {
  const [tab, setTab] = useState('list')
  const [filterText, setFilterText] = useState('')
  const routes = useSelector((state: RootStateType) => state.routes)

  const handleFilterChange = useCallback(({ target: { value } }) => setFilterText(value), [])
  const handleClearClick = useCallback(() => {
    setFilterText('')
  }, [])

  useEffect(() => {
    document.title = `Список маршрутов | Новая маршрутная сеть Воронежа`
    if (process.env.NODE_ENV === 'production') {
      ym('hit', '/routes')
    }
  }, [])

  const grouppedRoutes = groupBy(routes, (route) => route.newRouteStatus.name)

  return (
    <div className='b-routes-screen'>
      <div className='b-routes-tabs'>
        <span onClick={() => setTab('list')} className={`b-routes-tab ${tab === 'list' && 'active'}`}>
          Списком
        </span>{' '}
        |{' '}
        <span onClick={() => setTab('group')} className={`b-routes-tab ${tab === 'group' && 'active'}`}>
          По статусам
        </span>
      </div>

      <div>
        <label htmlFor='filter' className='b-input-label'>
          Быстрый поиск
        </label>
        <input
          id='filter'
          type='text'
          value={filterText}
          onChange={handleFilterChange}
          placeholder='37, например'
          className='b-input'
        />
        {filterText.length > 0 && (
          <span onClick={handleClearClick} className='b-clear-input'>
            Очистить
          </span>
        )}
      </div>

      {tab === 'list' && (
        <div className='b-routes-screen__list'>
          <div className='b-routes-list'>
            {routes
              .filter((route) => route.name.includes(filterText))
              .sort((a, b) => collator.compare(a.name, b.name))
              .map((route: RouteType) => (
                <Link
                  key={route.id}
                  to={`/routes/${route.name}`}
                  className={`b-routes-list__item ${routeStatus(route.newRouteStatus.name)}`}
                  title={route.newRouteStatus.name}
                >
                  {route.name}
                </Link>
              ))}
          </div>
        </div>
      )}

      {tab === 'group' && (
        <div className='b-routes-screen__list'>
          {Object.keys(grouppedRoutes).map((status) => {
            const filteredGrouppedRoutes = grouppedRoutes[status]
              .filter((route) => route.name.includes(filterText))
              .sort((a, b) => collator.compare(a.name, b.name))
            if (filteredGrouppedRoutes.length === 0) {
              return null
            }
            return (
              <div key={status}>
                <h2>
                  {status} ({grouppedRoutes[status].length})
                </h2>
                <div className='b-routes-list'>
                  {filteredGrouppedRoutes.map((route: RouteType) => (
                    <Link
                      key={route.id}
                      to={`/routes/${route.name}`}
                      className={`b-routes-list__item ${routeStatus(route.newRouteStatus.name)}`}
                      title={route.newRouteStatus.name}
                    >
                      {route.name}
                    </Link>
                  ))}
                </div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
})

RoutesScreen.displayName = 'RoutesScreen'

export default RoutesScreen
