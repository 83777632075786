export interface SearchScoreType {
  found: boolean
  score: number
}

export const compareByScore = (a, b) => {
  if (a.score > b.score) {
    return 1
  }
  if (a.score < b.score) {
    return -1
  }
  return 0
}

export const fuzzysearch = (needle: string, haystack: string) => {
  const hlen = haystack.length
  const nlen = needle.length
  if (nlen > hlen || nlen === 0) {
    return false
  }

  if (nlen === hlen) {
    return needle === haystack
  }

  if (haystack.indexOf(needle) !== -1) {
    return true
  }

  let position = 0
  for (let i = 0; i < nlen; i++) {
    const nch = needle.charAt(i)
    position = haystack.indexOf(nch, position) + 1
    if (position === 0) {
      return false
    }
  }
  return true
}

export const fuzzysearchScore = (needle: string, haystack: string): SearchScoreType => {
  const hlen = haystack.length
  const nlen = needle.length
  const notFound: SearchScoreType = { found: false, score: 100 }
  const found: SearchScoreType = { found: true, score: 0 }
  if (nlen > hlen || nlen === 0) {
    return notFound
  }

  if (nlen === hlen) {
    return needle === haystack ? found : notFound
  }

  const beginPosition = haystack.indexOf(needle)
  if (beginPosition !== -1) {
    return { found: true, score: beginPosition }
  }

  let position = haystack.indexOf(needle.charAt(0))
  let skipPenalty = 1
  let score = 0
  for (let i = 0; i < nlen; i++) {
    const nch = needle.charAt(i)
    const prevPosition = position + 1
    position = haystack.indexOf(nch, position) + 1
    const distanceSearch = position - prevPosition
    score += distanceSearch * skipPenalty
    if (distanceSearch > 0) {
      skipPenalty *= 5
    }
    if (position === 0) {
      return notFound
    }
  }

  return { found: true, score: score }
}
