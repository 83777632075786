import React, { memo } from 'react'
import { isEqual } from 'lodash'

import { RouteType } from '../../types'

import RouteLinkFromShortRoute from './RouteLinkFromShortRoute'

const StationRoutes = memo(
  ({
    stationRoutes,
    currentRoute,
    handleAddRoute,
  }: {
    stationRoutes: RouteType[]
    currentRoute: RouteType[]
    handleAddRoute: (selectedRoutes, addingRoute) => void
  }) => (
    <div className='b-station-routes'>
      <h3>Маршруты через выбранную остановку</h3>
      <div>
        {stationRoutes.map((stationRoute) => (
          <div
            key={stationRoute.id}
            className={`b-station-route ${
              Boolean(currentRoute) && isEqual(currentRoute, [stationRoute.id]) ? 'current' : ''
            }`}
          >
            <div className='b-station-route__content'>
              <RouteLinkFromShortRoute shortRoute={stationRoute} />
            </div>
            <div
              className='b-station-route__arrow'
              onClick={(): void =>
                handleAddRoute(
                  [stationRoute.forwardDirectionStations, stationRoute.backDirectionStations],
                  [stationRoute.id]
                )
              }
            />
          </div>
        ))}
      </div>
    </div>
  )
)

StationRoutes.displayName = 'StationRoutes'

export default StationRoutes
