import { RouteType, StationType } from '../../types'

const initialState: RootStateType = {
  routes: [],
  stations: [],
  stationsToRoutes: {},
  edges: [],
}

export interface RootStateType {
  routes: RouteType[]
  stations: StationType[]
  stationsToRoutes: {}
  edges: []
}

export default (state = initialState, action): RootStateType => {
  switch (action.type) {
    case 'SET_STATIONS': {
      return {
        ...state,
        stations: action.payload,
      }
    }

    case 'SET_ROUTES':
      return {
        ...state,
        routes: action.payload,
      }

    case 'SET_EDGES':
      return {
        ...state,
        edges: action.payload,
      }

    default:
      return state
  }
}
