import React, { memo, useEffect } from 'react'
import ym from 'react-yandex-metrika'

const AboutScreen = memo(() => {
  document.title = `О проекте | Новая маршрутная сеть Воронежа`
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ym('hit', '/about')
    }
  })

  return (
    <div className='l-container'>
      <h1>О проекте</h1>
      <div>
        <p>
          <a
            href='http://www.voronezh-city.ru/communications/msgs/detail/31650'
            target='_blank'
            rel='noopener noreferrer'
          >
            Сообщение
          </a>
          {' и '}
          <a
            href='http://www.voronezh-city.ru/documents/obyavleniya/2019/October/25/prezent.pdf'
            target='_blank'
            rel='noopener noreferrer'
          >
            презентация (PDF, 14 МБ)
          </a>
          {' на сайте мэрии про проект маршрутной сети'}
        </p>
        <p>Разработано для ФАУ "РосдорНИИ" и жителей города</p>
        <p>
          Разработчики САЙТА:{' '}
          <a href='https://vk.com/stereodenis' target='_blank' rel='noopener noreferrer'>
            Евграфов Денис
          </a>{' '}
          (
          <a href='https://vk.com/makevrn' target='_blank' rel='noopener noreferrer'>
            Удобный Воронеж
          </a>
          ) и{' '}
          <a href='https://vk.com/matematic' target='_blank' rel='noopener noreferrer'>
            Емельянов Павел
          </a>
        </p>
        <p>
          Нашли ошибку на САЙТЕ? <a href='mailto:mail@vrnroutes.ru'>Напишите нам</a>
        </p>
      </div>
    </div>
  )
})

AboutScreen.displayName = 'AboutScreen'

export default AboutScreen
