import React, { memo, useEffect } from 'react'
import L from 'leaflet'

import { MapType } from '../../types'

const Map = memo(({ mapRef, className }: { mapRef: MapType; className?: string }) => {
  useEffect(() => {
    // if (mapRef.current) {
    //   mapRef.current.remove()
    // }
    mapRef.current = L.map('map', {
      center: [51.660768, 39.200226],
      zoom: 13,
    })
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(mapRef.current)
  }, [mapRef])

  return <div className={`b-route-map ${className}`} id='map' />
})

export default Map
