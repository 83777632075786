import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { RouteType } from '../../types'
import { RootStateType } from '../../redux/reducers'

const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })

export const routeStatus = (status: string): string => {
  switch (status) {
    case 'Ликвидируется':
      return 'removed'
    case 'Сохраняется':
      return 'saved'
    case 'Новый':
      return 'new'
    case 'Изменяется':
      return 'changed'
    default:
      return ''
  }
}

const MainScreen = memo(() => {
  const routes = useSelector((state: RootStateType) => state.routes)

  return (
    <div className='l-container'>
      <div className='b-main-screen'>
        <h3>Уважаемые жители и гости города Воронежа,</h3>
        <p>
          В настоящее время для Вашего города ФАУ «РосдорНИИ» (г. Москва) разработаны проекты документов транспортного
          планирования. С целью разработки оптимального набора мероприятий в рамках работы были выполнены:
        </p>
        <ul>
          <li>натурные обследования транспортных потоков;</li>
          <li>натурные исследования пассажиропотоков;</li>
          <li>
            социологические исследования, включающие вопросы по системе транспорта общего пользования (опрошено около 6
            тыс. респондентов);
          </li>
          <li>получены данные от сотовых операторов (BIG DATA), для построения транспортных моделей;</li>
          <li>
            получены обращения граждан, поступавшие в администрацию города, о существующих недостатках
            дорожно-транспортном хозяйстве города;
          </li>
          <li>
            проведен комплексный анализ транспортной инфраструктуры и сети городского пассажирского транспорта общего
            пользования.
          </li>
        </ul>
        <p>
          На основе собранных данных и проведенного анализа был разработан перечень мероприятий по повышению
          эффективности работы транспортной инфраструктуры, безопасности дорожного движения и оптимизации сети
          городского пассажирского транспорта общего пользования.
        </p>
        <p>
          В рамках мероприятий по оптимизации сети городского пассажирского транспорта общего пользования планируется
          внесение изменений в отдельные маршруты общественного транспорта. С перечнем изменений маршрутов Вы можете
          ознакомиться на данном ресурсе (нажмите на номер маршрута, чтобы узнать подробности):
        </p>
        <div className='b-routes-list'>
          {routes
            .sort((a, b) => collator.compare(a.name, b.name))
            .map((route: RouteType) => (
              <Link
                key={route.id}
                to={`/routes/${route.name}`}
                className={`b-routes-list__item ${routeStatus(route.newRouteStatus.name)}`}
                title={route.newRouteStatus.name}
              >
                {route.name}
              </Link>
            ))}
        </div>
        <p>
          На странице <Link to='/stations'>"Как доехать?"</Link>, можно узнать с помощью каких автобусов, вы сможете
          добраться от одной до другой остановки.
        </p>
        <p>
          Так к основным изменениям можно отнести подбор оптимального класса транспортных средств и интервалов их
          движения.
        </p>
        <p>Так в новой сети общественного транспорта предлагается использовать следующую классификацию:</p>
        <ul>
          <li>малый класс вместимостью 42 места (ПАЗ-3205 и его аналоги);</li>
          <li>средний класс вместимостью 72 места (МАЗ–206 и его аналоги);</li>
          <li>большой класс вместимостью 105 мест (ЛиАЗ-5292 и его аналоги);</li>
          <li>особо большой класс вместимостью 175 мест (МАЗ-205 и его аналоги).</li>
        </ul>
        <p>
          В новой сети предлагается организовать обслуживание территорий нового жилищного строительства (ул.
          Ипподромная, мкр. Шилово, ул. Шидловского и др.) территорий, имеющих слабую доступность (ул. Дорожная, ул.
          Ржевская и др.), общественным транспортом.
        </p>
        <blockquote>
          Реформы сложнее и ответственнее, чем реформа общественного транспорта, в нашем городе невозможно представить.
          Новая схема предусматривает сокращение общего количества автобусных маршрутов без ущерба для разветвленности
          сети. Ни одна улица, по которой сейчас ходит транспорт, не остается без него. Я ставлю перед сотрудниками
          управления транспорта задачу проанализировать все конструктивные предложения воронежцев. Локальные коррективы
          возможны.
          <span>Вадим Кстенин, мэр Воронежа</span>
        </blockquote>
      </div>
    </div>
  )
})

MainScreen.displayName = 'MainScreen'

export default MainScreen
