import React, { memo, useEffect } from 'react'
import './App.scss'
import './header.scss'
import { BrowserRouter as Router, NavLink, Route, Switch } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { noop } from 'lodash'

import Stations from './Screens/StationsScreen'
import Routes from './Screens/RoutesScreen'
import RouteScreen from './Screens/RouteScreen'
import { fetchRoutes, fetchStations, fetchEdges, fetchOldRoutes } from './actions'
import AboutScreen from './Screens/AboutScreen'
import MainScreen from './Screens/MainScreen'

const App: React.FC = memo(() => {
  const dispatch = useDispatch()
  useEffect(() => {
    Promise.all([fetchStations(), fetchEdges(), fetchRoutes(), fetchOldRoutes()])
      .then((responses) => {
        const stations = responses[0].body
        dispatch({
          type: 'SET_STATIONS',
          payload: stations,
        })
        dispatch({
          type: 'SET_EDGES',
          payload: responses[1].body,
        })

        const routes = responses[2].body
        const oldRoutes = responses[3].body

        const preparedRoutes = routes.map((route) => {
          let newRoute = route
          if (['Ликвидируется', 'Сохраняется'].includes(route.newRouteStatus.name)) {
            const oldRoute = oldRoutes.find((r) => r.id === route.id)
            newRoute = { ...newRoute, ...oldRoute }
          }

          const forwardDirectionStations = newRoute.forwardDirectionStations.map(
            (stationId) => stations.find((st) => st.id === stationId) || stationId
          )
          const backDirectionStations = newRoute.backDirectionStations.map(
            (stationId) => stations.find((st) => st.id === stationId) || stationId
          )
          return {
            ...newRoute,
            forwardDirectionStations,
            backDirectionStations,
          }
        })
        dispatch({
          type: 'SET_ROUTES',
          payload: preparedRoutes,
        })
      })
      .catch(noop)
  }, [dispatch])

  return (
    <Router>
      <header className='header-login-signup'>
        <div className='header-limiter'>
          <NavLink to='/' className='b-header-logo'>
            <img src={require('./icon.png')} alt='' />
            <span>Новая маршрутная сеть Воронежа</span>
          </NavLink>

          <nav>
            <NavLink exact to='/routes' activeClassName='selected'>
              Маршруты
            </NavLink>

            <NavLink exact to='/stations' activeClassName='selected'>
              Как доехать
            </NavLink>

            <NavLink exact to='/about' activeClassName='selected'>
              О проекте
            </NavLink>
          </nav>
        </div>
      </header>

      <Switch>
        <Route exact path='/'>
          <MainScreen />
        </Route>

        <Route exact path='/stations'>
          <Stations />
        </Route>

        <Route exact path={`/routes`}>
          <Routes />
        </Route>

        <Route exact path={`/routes/:routeName`}>
          <RouteScreen />
        </Route>

        <Route exact path={`/about`}>
          <AboutScreen />
        </Route>
      </Switch>
    </Router>
  )
})

App.displayName = 'App'

export default App
