import React, { memo } from 'react'
import { isEqual } from 'lodash'

import { RouteType, DirectDirectionType } from '../../types'

import RouteLinkFromShortRoute from './RouteLinkFromShortRoute'

const TransferRoutes = memo(
  ({
    oneTransferRoutes,
    currentRoute,
    handleAddRoute,
  }: {
    currentRoute: RouteType[]
    oneTransferRoutes: DirectDirectionType[][]
    handleAddRoute: (selectedRoutes, addingRoute) => void
  }) => (
    <div className='b-station-routes'>
      <h3>Маршруты с пересадкой</h3>
      <div>
        {oneTransferRoutes.map((oneTransferRoute) => (
          <div
            className={`b-station-route ${
              Boolean(currentRoute) &&
              isEqual(currentRoute, [
                ...oneTransferRoute[0].routes.map((r) => r.id),
                ...oneTransferRoute[1].routes.map((r) => r.id),
              ])
                ? 'current'
                : ''
            }`}
            key={[
              ...oneTransferRoute[0].routes.map((r) => r.id),
              ...oneTransferRoute[1].routes.map((r) => r.id),
            ].join()}
          >
            <div className='b-station-route__content'>
              <>
                {oneTransferRoute[0].routes.map((route) => (
                  <RouteLinkFromShortRoute shortRoute={route} key={route.id} />
                ))}
              </>
              <span>=&gt; </span>
              <>
                {oneTransferRoute[1].routes.map((route) => (
                  <RouteLinkFromShortRoute shortRoute={route} key={route.id} />
                ))}
              </>
              <br />
              <div>пересадка: {oneTransferRoute[1].busStations[0].name}</div>
            </div>
            <div
              className='b-station-route__arrow'
              onClick={(): void =>
                handleAddRoute(
                  [oneTransferRoute[0].busStations, oneTransferRoute[1].busStations],
                  [...oneTransferRoute[0].routes.map((r) => r.id), ...oneTransferRoute[1].routes.map((r) => r.id)]
                )
              }
            />
          </div>
        ))}
      </div>
    </div>
  )
)

TransferRoutes.displayName = 'TransferRoutes'

export default TransferRoutes
