import { Link } from 'react-router-dom'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'

import { RouteType, ShortRouteType } from '../../types'
import { routeStatus } from '../MainScreen'
import { RootStateType } from '../../redux/reducers'

const RouteLinkFromShortRoute = memo(({ shortRoute }: { shortRoute: RouteType | ShortRouteType }) => {
  const routes = useSelector((state: RootStateType) => state.routes)
  const route = routes.find((value) => value.id === shortRoute.id)
  if (!route) {
    return
  }

  return (
    <Link
      key={route.id}
      to={`/routes/${route.name}`}
      className={`b-routes-list__item ${routeStatus(route.newRouteStatus.name)}`}
      title={route.newRouteStatus.name}
    >
      {route.name}
    </Link>
  )
})

RouteLinkFromShortRoute.displayName = 'RouteLinkFromShortRoute'

export default RouteLinkFromShortRoute
