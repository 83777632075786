import React, { memo } from 'react'
import { isEqual } from 'lodash'

import { RouteType, DirectDirectionType } from '../../types'

import RouteLinkFromShortRoute from './RouteLinkFromShortRoute'

const DirectRoutes = memo(
  ({
    directRoutes,
    currentRoute,
    handleAddRoute,
  }: {
    directRoutes: DirectDirectionType[]
    currentRoute: RouteType[]
    handleAddRoute: (selectedRoutes, addingRoute) => void
  }) => (
    <div className='b-station-routes'>
      <h3>Прямые маршруты</h3>
      <div>
        {directRoutes.map((directRoute) => (
          <div
            className={`b-station-route ${
              Boolean(currentRoute) &&
              isEqual(
                currentRoute,
                directRoute.routes.map((r) => r.id)
              )
                ? 'current'
                : ''
            }`}
            key={directRoute.routes.map((r) => r.id).join()}
          >
            <div className='b-station-route__content'>
              {directRoute.routes.map((route) => (
                <RouteLinkFromShortRoute shortRoute={route} key={route.id} />
              ))}
            </div>
            <div
              className='b-station-route__arrow'
              onClick={(): void =>
                handleAddRoute(
                  [directRoute.busStations],
                  directRoute.routes.map((r) => r.id)
                )
              }
            />
          </div>
        ))}
      </div>
    </div>
  )
)

DirectRoutes.displayName = 'DirectRoutes'

export default DirectRoutes
