import React, { memo, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ym from 'react-yandex-metrika'
import I18n from 'i18n-js'

import Map from '../../Components/Map'
import { RootStateType } from '../../redux/reducers'
import ScrollToTop from '../../Components/ScrollToTop'
import { addRoutes } from '../../actions'

I18n.pluralization.ru = (count: number) => {
  const key =
    count % 10 === 1 && count % 100 !== 11
      ? 'one'
      : [2, 3, 4].indexOf(count % 10) >= 0 && [12, 13, 14].indexOf(count % 100) < 0
      ? 'few'
      : count % 10 === 0 || [5, 6, 7, 8, 9].indexOf(count % 10) >= 0 || [11, 12, 13, 14].indexOf(count % 100) >= 0
      ? 'many'
      : 'other'
  return [key]
}

I18n.translations.ru = {
  buses: {
    // zero: '{{count}} машин',
    one: '{{count}} машина',
    few: '{{count}} машины',
    many: '{{count}} машин',
    other: '{{count}} машины',
  },
  minutes: {
    // zero: '{{count}} машин',
    one: '{{count}} минут',
    few: '{{count}} минуты',
    many: '{{count}} минут',
    other: '{{count}} минуты',
  },
}

I18n.locale = 'ru'

const numberText = ['В часы пик', 'В остальное время']

const RouteScreen = memo(() => {
  const mapRef = useRef(null)
  const edges = useSelector((state: RootStateType) => state.edges)
  const routes = useSelector((state: RootStateType) => state.routes)
  const stations = useSelector((state: RootStateType) => state.stations)
  const { routeName } = useParams<{ routeName: string }>()

  const route = routes.find((r) => r.name === routeName)
  if (route) {
    document.title = `Маршрут ${routeName} (${route.newRouteStatus.name}) | Новая маршрутная сеть Воронежа`
  }

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ym('hit', `/routes/${routeName}`)
    }
  }, [routeName])

  useEffect(() => {
    if (Boolean(route) && stations.length > 0 && mapRef.current) {
      addRoutes(stations, route, mapRef, edges)
    }
  }, [edges, route, stations])

  return (
    <div>
      <ScrollToTop />
      <div className='b-route__container'>
        <h1 className='b-route__header'>
          Маршрут {routeName}
          {Boolean(route) && ` (${route.newRouteStatus.name})`}
        </h1>
        <h3 className='b-route__subheader'>{route && route.description}</h3>
        {route && Boolean(route.newRouteBusType) && <p>{route.newRouteBusType.name}</p>}
      </div>

      {route && <Map mapRef={mapRef} />}

      {route && (
        <div className='b-route__info'>
          {Boolean(route && route.changeDescription) && (
            <>
              <h2>Описание изменений</h2>
              <p>{route.changeDescription}</p>
            </>
          )}
          {Boolean(route.numberOfBuses) && (
            <>
              <h2>Количество машин</h2>
              <>
                {route.numberOfBuses.split('/').map((n, index) => (
                  <p key={index}>{`${numberText[index]}: ${I18n.t('buses', { count: Number(n) })}`}</p>
                ))}
              </>
            </>
          )}
          {Boolean(route.intervalOfBuses) && (
            <>
              <h2>Интервал (пиковый и межпиковый)</h2>
              <>
                {route.intervalOfBuses.split('/').map((n, index) => (
                  <p key={index}>{`${numberText[index]}: ${I18n.t('minutes', { count: Number(n) })}`}</p>
                ))}
              </>
            </>
          )}
          {Boolean(route.forwardDirectionStations) && route.forwardDirectionStations.length > 0 && (
            <>
              <h2>Остановки</h2>
              <p>{route.forwardDirectionStations.map((d) => d.name).join(' – ')}</p>
            </>
          )}
          {Boolean(route.backDirectionStations) && route.backDirectionStations.length > 0 && (
            <>
              <h2>Остановки (обратно)</h2>
              <p>{route.backDirectionStations.map((d) => d.name).join(' – ')}</p>
            </>
          )}
        </div>
      )}
    </div>
  )
})

RouteScreen.displayName = 'RouteScreen'

export default RouteScreen
