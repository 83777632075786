import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import { Provider } from 'react-redux'
import { YMInitializer } from 'react-yandex-metrika'

import App from './App'
import * as serviceWorker from './serviceWorker'
import store from './redux/store'

ReactDOM.render(
  <Provider store={store}>
    {process.env.NODE_ENV === 'production' && (
      <YMInitializer
        accounts={[55950523]}
        options={{
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          trackHash: true,
        }}
      />
    )}
    <App />
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
